import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'normalize.css/normalize.css'
// 移动端适配
import 'lib-flexible'

// 引入Animate.css动画
import 'animate.css';
// import 'normalize.css'
import 'lib-flexible/flexible.js'


Vue.config.productionTip = false


Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
  
}).$mount('#app')
